import React from 'react';
// Pieces imports
import { SEO, Heading, Box } from 'pieces';
// sections

const SetUndEventBauPage = () => (
  <>
    <SEO title='Set- und Eventbau' />
    <Box sx={{ minHeight: '100vh' }}>
      <Heading>Set- und Eventbau</Heading>
    </Box>
  </>
);

export default SetUndEventBauPage;
